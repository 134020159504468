import axios from "axios";

const url = process.env.REACT_APP_URL;

const sendMail = async (body, setLoading) => {
  try {
    setLoading(true);
    console.log("data", body);

    const response = await axios.post(`${url}/api/mails/enquiry`, body);
    return response.data;
  } catch (error) {
    console.error("Error sending mail:", error.message || error);
    throw error;
  } finally {
    setLoading(false);
  }
};

export default sendMail;
