import { Typography } from "@material-tailwind/react";
import React from "react";

const Privacy = () => {
  return (
    <div className="min-h-screen flex flex-col items-center w-full py-14 px-12">
      <Typography className="text-center w-full text-6xl mb-6 font-bold font-playfair text-text-500">
      Privacy Policy
      </Typography>
      <Typography className="text-center text-base mb-6 font-normal font-playfair text-text-500">
      These Terms are entered between you and Muktanam Ayurveda Services.
      </Typography>

      <div className="w-full max-w-5xl flex flex-col justify-center p-12 gap-6 items-center  rounded-2xl">
        {/* Introduction */}
        <div className="w-full h-full  rounded-2xl">
          <Typography className="font-montserrat font-semibold text-lg text-text-500">
            1. Introduction
          </Typography>
          <Typography className="font-montserrat font-medium text-sm text-text-500">
            Muktanam Ayurveda Services ("Muktanam Ayurveda", "we", "us", "our") is committed
            to protecting your privacy. This Privacy Policy outlines how we
            collect, use, disclose, and protect your information when you use
            our website, mobile application, and services (collectively, the
            "Services"). By accessing or using the Services, you agree to the
            terms of this Privacy Policy. If you do not agree with this Privacy
            Policy, please do not use our Services.
          </Typography>
        </div>

        {/* Information We Collect */}
        <div className="w-full h-full  rounded-2xl">
          <Typography className="font-montserrat font-semibold text-lg text-text-500">
            2. Information We Collect
          </Typography>

          <Typography className="font-montserrat font-medium text-sm text-text-500">
            <strong>2.1. Personal Information</strong> <br />
            We collect personal information that you provide to us when you
            create an account, book a ride, or communicate with us. This
            information may include:
            <ul className="list-disc ml-6">
              <li>Name</li>
              <li>Email address</li>
              <li>Phone number</li>
              <li>Payment information</li>
              <li>Pickup and drop-off locations</li>
              <li>Other information you choose to provide</li>
            </ul>
          </Typography>

          <Typography className="font-montserrat font-medium text-sm text-text-500">
            <strong>2.2. Usage Information</strong> <br />
            We collect information about your use of our Services, including:
            <ul className="list-disc ml-6">
              <li>Details of your rides (e.g., date, time, distance, fare)</li>
              <li>Browser and device information</li>
              <li>IP address</li>
              <li>Cookies and similar tracking technologies</li>
            </ul>
          </Typography>

          <Typography className="font-montserrat font-medium text-sm text-text-500">
            <strong>2.3. Location Information</strong> <br />
            With your consent, we collect and use your location information to
            provide our Services, including real-time ride tracking and accurate
            pickup and drop-off locations.
          </Typography>
        </div>

        {/* How We Use Your Information */}
        <div className="w-full h-full  rounded-2xl">
          <Typography className="font-montserrat font-semibold text-lg text-text-500">
            3. How We Use Your Information
          </Typography>
          <Typography className="font-montserrat font-medium text-sm text-text-500">
            We use your information to:
            <ul className="list-disc ml-6">
              <li>Provide, maintain, and improve our Services</li>
              <li>Process and manage your bookings and payments</li>
              <li>
                Communicate with you, including sending confirmations, updates,
                and support messages
              </li>
              <li>
                Personalize your experience and provide tailored content and
                recommendations
              </li>
              <li>
                Ensure the safety and security of our Services
              </li>
              <li>Conduct research and analysis to improve our Services</li>
              <li>
                Comply with legal obligations and protect our legal rights
              </li>
            </ul>
          </Typography>
        </div>

        {/* Sharing Your Information */}
        <div className="w-full h-full  rounded-2xl">
          <Typography className="font-montserrat font-semibold text-lg text-text-500">
            4. Sharing Your Information
          </Typography>
          <Typography className="font-montserrat font-medium text-sm text-text-500">
            We may share your information with:
            <ul className="list-disc ml-6">
              <li>
                Service Providers: Third-party vendors who perform services on
                our behalf, such as payment processing, data analysis, and
                customer support.
              </li>
              <li>
                Drivers: Your information is shared with drivers to facilitate
                your ride bookings.
              </li>
              <li>
                Legal and Regulatory Authorities: When required by law or to
                protect our rights, we may disclose your information to relevant
                authorities.
              </li>
              <li>
                Business Transfers: In the event of a merger, acquisition, or
                sale of assets, your information may be transferred as part of
                the transaction.
              </li>
            </ul>
          </Typography>
        </div>

        {/* Data Security */}
        <div className="w-full h-full  rounded-2xl">
          <Typography className="font-montserrat font-semibold text-lg text-text-500">
            5. Data Security
          </Typography>
          <Typography className="font-montserrat font-medium text-sm text-text-500">
            We implement appropriate technical and organizational measures to
            protect your information against unauthorized access, alteration,
            disclosure, or destruction. However, no method of transmission over
            the internet or electronic storage is completely secure, and we
            cannot guarantee absolute security.
          </Typography>
        </div>

        {/* Your Rights */}
        <div className="w-full h-full  rounded-2xl">
          <Typography className="font-montserrat font-semibold text-lg text-text-500">
            6. Your Rights
          </Typography>
          <Typography className="font-montserrat font-medium text-sm text-text-500">
            You have the right to:
            <ul className="list-disc ml-6">
              <li>Access and update your personal information</li>
              <li>Request the deletion of your personal information</li>
              <li>
                Object to or restrict the processing of your personal
                information
              </li>
              <li>
                Withdraw your consent at any time, where we rely on your consent
                to process your information
              </li>
              <li>
                Lodge a complaint with a data protection authority
              </li>
            </ul>
          </Typography>
        </div>

        {/* Children's Privacy */}
        <div className="w-full h-full  rounded-2xl">
          <Typography className="font-montserrat font-semibold text-lg text-text-500">
            7. Children's Privacy
          </Typography>
          <Typography className="font-montserrat font-medium text-sm text-text-500">
            Our Services are not intended for children under the age of 18. We
            do not knowingly collect personal information from children under 18.
            If we become aware that we have inadvertently collected personal
            information from a child under 18, we will take steps to delete such
            information as soon as possible.
          </Typography>
        </div>

        {/* Changes to This Privacy Policy */}
        <div className="w-full h-full  rounded-2xl">
          <Typography className="font-montserrat font-semibold text-lg text-text-500">
            8. Changes to This Privacy Policy
          </Typography>
          <Typography className="font-montserrat font-medium text-sm text-text-500">
            We may update this Privacy Policy from time to time. We will notify
            you of any changes by posting the new Privacy Policy on our
            platform. Your continued use of the Services after any such changes
            constitutes your acceptance of the new Privacy Policy.
          </Typography>
        </div>

        {/* Contact Us */}
        <div className="w-full h-full  rounded-2xl">
          <Typography className="font-montserrat font-semibold text-lg text-text-500">
            9. Contact Us
          </Typography>
          <Typography className="font-montserrat font-medium text-sm text-text-500">
            If you have any questions about this Privacy Policy, please contact
            us at:
            <br />
            Email: muktanamayurveda@gmail.com
            <br />
            Phone: +91-9520205177
            <br />
            Address: Delhi-Haridwar National Highway, Near Bahadrabad, Haridwar-249405, Uttarakhand, Bharat
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default Privacy;
