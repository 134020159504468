import { FaWhatsapp } from "react-icons/fa";

const WhatsAppLink = ({ message }) => {
  const encodedMessage = encodeURIComponent(message);

  return (
    <a
      href={`https://wa.me/919520205177?text=${encodedMessage}`}
      target="_blank"
      rel="noopener noreferrer"
      className="flex items-center gap-2 text-green-600 text-xl font-medium border-2 border-green-600 rounded-xl p-2">
      <FaWhatsapp size={24} />
      Message Us on WhatsApp
    </a>
  );
};
export default WhatsAppLink
