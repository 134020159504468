

import React from "react";
import {
    Card,
    Typography,
    List,
    ListItem,
    ListItemPrefix,
} from "@material-tailwind/react";
import avatar from '../../assets/images/dummy_avatar.png';

function SidebarWithLogo() {
    const [open, setOpen] = React.useState(0);
    const [openAlert, setOpenAlert] = React.useState(true);

    const handleOpen = (value) => {
        setOpen(open === value ? 0 : value);
    };

    return (
        <Card className="sticky top-0 -z-50 h-screen w-full max-w-[17rem] p-4 shadow-xl shadow-blue-gray-900/5 border-2 rounded-none">
            <div className="mb-2 flex items-center justify-center gap-4 p-4">
                <Typography variant="h5" className="text-textLight-400 text-md">
                    Community
                </Typography>
            </div>
            <div className="mb-2 flex flex-col items-center justify-center gap-4 p-4">
                <img src={avatar} alt="brand" className="h-16 w-16" />
                <Typography className="text-textLight-500 font-archivo text-base font-bold">Jack James</Typography>
                <div className="flex gap-6">
                    <div className="flex flex-col items-center justify-center">
                        <Typography className="font-archivo font-bold text-sm text-textLight-500">1.6M</Typography>
                        <Typography className="font-rubik text-xs text-textLight-300">Followers</Typography>
                    </div>
                    <div className="flex flex-col items-center justify-center">
                        <Typography className="font-archivo font-bold text-sm text-textLight-500">12</Typography>
                        <Typography className="font-rubik text-xs text-textLight-300">Following</Typography>
                    </div>
                </div>
            </div>
            <div className="flex flex-col justify-between h-screen">
                <List>
                    <ListItem>
                        <ListItemPrefix>
                        </ListItemPrefix>
                        <Typography className="font-medium font-archivo text-base text-[#2A2A2E]">
                            Home
                        </Typography>
                    </ListItem>
                    <ListItem selected={open === 2}>
                        <ListItemPrefix>
                        </ListItemPrefix>
                        <Typography className="font-medium font-archivo text-base text-[#2A2A2E]">
                            Profile
                        </Typography>
                    </ListItem>
                    <ListItem>
                        <ListItemPrefix>
                        </ListItemPrefix>
                        <Typography className="font-medium font-archivo text-base text-[#2A2A2E]">
                            My Post
                        </Typography>
                    </ListItem>
                    <ListItem>
                        <ListItemPrefix>
                        </ListItemPrefix>
                        <Typography className="font-medium font-archivo text-base text-[#2A2A2E]">
                            Settings
                        </Typography>
                    </ListItem>
                </List>
                <List>
                    <ListItem>
                        <ListItemPrefix>
                        </ListItemPrefix>
                        <Typography className="font-medium font-archivo text-base text-[#2A2A2E]">
                            Logout
                        </Typography>
                    </ListItem>
                </List>
            </div>
        </Card>
    );
}

export default SidebarWithLogo;