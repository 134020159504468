import { Typography } from '@material-tailwind/react'
import React from 'react'

const TermsAndConditions = () => {
  return (
    <div className="min-h-screen flex flex-col items-center w-full py-14 px-12">
    <Typography className="text-center w-full text-6xl mb-6 font-bold font-playfair text-text-500">
    Terms & Conditions 
    </Typography>
    <Typography className="text-center text-base mb-6 font-normal font-playfair text-text-500">
    These Terms are entered between you and Muktanam Ayurveda Services.
    </Typography>

    <div className="w-full max-w-5xl flex flex-col justify-center p-12 gap-6 items-center  rounded-2xl">
        {/* Section 1 - Introduction */}
        <div className="w-full h-full  rounded-2xl">
          <Typography className="font-montserrat font-semibold text-lg text-text-500">
            1. Introduction
          </Typography>
          <Typography className="font-montserrat font-medium text-sm text-text-500">
            Welcome to Muktanam Ayurveda Services ("Muktanam Ayurveda", "we", "us", "our"). These Terms and Conditions ("Terms") govern your use of our website, mobile application, and services (collectively, the "Services"). By accessing or using our Services, you agree to comply with these Terms. If you do not agree to these Terms, you may not use our Services.
          </Typography>
        </div>

        {/* Section 2 - Services Provided */}
        <div className="w-full h-full  rounded-2xl">
          <Typography className="font-montserrat font-semibold text-lg text-text-500">
            2. Services Provided
          </Typography>
          <Typography className="font-montserrat font-medium text-sm text-text-500">
            Muktanam Ayurveda offers chauffeur services, including but not limited to ride-hailing, scheduled rides, and corporate transport solutions. Our Services are provided through our platform, where users can book rides, manage their bookings, and make payments.
          </Typography>
        </div>

        {/* Section 3 - User Accounts */}
        <div className="w-full h-full  rounded-2xl">
          <Typography className="font-montserrat font-semibold text-lg text-text-500">
            3. User Accounts
          </Typography>
          <Typography className="font-montserrat font-medium text-sm text-text-500">
            <strong>3.1 Registration:</strong> You must create an account by providing accurate and complete information. You are responsible for maintaining the confidentiality of your account information and for all activities that occur under your account.
            <br />
            <strong>3.2 Eligibility:</strong> You must be at least 18 years old to create an account and use our Services. By creating an account, you represent and warrant that you meet this eligibility requirement.
          </Typography>
        </div>

        {/* Section 4 - Booking and Payment */}
        <div className="w-full h-full  rounded-2xl">
          <Typography className="font-montserrat font-semibold text-lg text-text-500">
            4. Booking and Payment
          </Typography>
          <Typography className="font-montserrat font-medium text-sm text-text-500">
            <strong>4.1 Booking:</strong> You can book a ride through our platform. All bookings are subject to availability and confirmation by us.
            <br />
            <strong>4.2 Payment:</strong> Payment for rides must be made through our platform using a valid payment method. By providing payment information, you authorize us to charge your payment method for the total amount of your booking, including any applicable taxes and fees.
            <br />
            <strong>4.3 Cancellation and Refunds:</strong> You may cancel a booking subject to our cancellation policy, which will be provided at the time of booking. Refunds for cancellations will be processed according to the terms specified in the cancellation policy.
          </Typography>
        </div>

        {/* Section 5 - User Conduct */}
        <div className="w-full h-full  rounded-2xl">
          <Typography className="font-montserrat font-semibold text-lg text-text-500">
            5. User Conduct
          </Typography>
          <Typography className="font-montserrat font-medium text-sm text-text-500">
            <strong>5.1 Prohibited Activities:</strong> You agree not to: <br />
            - Use the Services for any unlawful purpose.<br />
            - Harass, abuse, or harm another person using our Services.<br />
            - Impersonate any person or entity, or falsely state or otherwise misrepresent your affiliation with a person or entity.<br />
            - Interfere with or disrupt the Services or servers or networks connected to the Services.
            <br />
            <strong>5.2 Driver Conduct:</strong> Our drivers are expected to provide services in a professional manner. Any misconduct should be reported to us immediately.
          </Typography>
        </div>

        {/* Section 6 - Liability */}
        <div className="w-full h-full  rounded-2xl">
          <Typography className="font-montserrat font-semibold text-lg text-text-500">
            6. Liability
          </Typography>
          <Typography className="font-montserrat font-medium text-sm text-text-500">
            <strong>6.1 Limitation of Liability:</strong> To the maximum extent permitted by law, Muktanam Ayurveda shall not be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly, or any loss of data, use, goodwill, or other intangible losses, resulting from:
            <br />- Your use or inability to use the Services.
            <br />- Any conduct or content of any third party on the Services.
            <br />- Any unauthorized access, use, or alteration of your transmissions or content.
            <br />
            <strong>6.2 Disclaimer of Warranties:</strong> Our Services are provided "as is" and "as available" without any warranties of any kind, whether express or implied.
          </Typography>
        </div>

        {/* Section 7 - Indemnification */}
        <div className="w-full h-full  rounded-2xl">
          <Typography className="font-montserrat font-semibold text-lg text-text-500">
            7. Indemnification
          </Typography>
          <Typography className="font-montserrat font-medium text-sm text-text-500">
            You agree to indemnify, defend, and hold harmless Muktanam Ayurveda and its affiliates, officers, directors, employees, and agents from and against any and all claims, liabilities, damages, losses, and expenses, including reasonable attorneys' fees and costs, arising out of or in any way connected with your access to or use of the Services.
          </Typography>
        </div>

        {/* Section 8 - Modifications to Terms */}
        <div className="w-full h-full  rounded-2xl">
          <Typography className="font-montserrat font-semibold text-lg text-text-500">
            8. Modifications to Terms
          </Typography>
          <Typography className="font-montserrat font-medium text-sm text-text-500">
            Muktanam Ayurveda reserves the right to modify these Terms at any time. We will notify you of any changes by posting the new Terms on our platform. Your continued use of the Services after any such changes constitutes your acceptance of the new Terms.
          </Typography>
        </div>

        {/* Section 9 - Termination */}
        <div className="w-full h-full  rounded-2xl">
          <Typography className="font-montserrat font-semibold text-lg text-text-500">
            9. Termination
          </Typography>
          <Typography className="font-montserrat font-medium text-sm text-text-500">
            We may terminate or suspend your account and access to the Services immediately, without prior notice or liability, for any reason, including if you breach these Terms.
          </Typography>
        </div>

        {/* Section 10 - Governing Law */}
        <div className="w-full h-full  rounded-2xl">
          <Typography className="font-montserrat font-semibold text-lg text-text-500">
            10. Governing Law
          </Typography>
          <Typography className="font-montserrat font-medium text-sm text-text-500">
            These Terms shall be governed and construed in accordance with the laws of the jurisdiction in which Muktanam Ayurveda operates, without regard to its conflict of law provisions.
          </Typography>
        </div>

        {/* Section 11 - Contact Us */}
        <div className="w-full h-full  rounded-2xl">
          <Typography className="font-montserrat font-semibold text-lg text-text-500">
            11. Contact Us
          </Typography>
          <Typography className="font-montserrat font-medium text-sm text-text-500">
            If you have any questions about these Terms, please contact us at:
            <br />
            Email: muktanamayurveda@gmail.com
            <br />
            Phone: +91-9520205177
          </Typography>
        </div>
      </div>
  </div>
  )
}

export default TermsAndConditions