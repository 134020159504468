import { Outlet } from "react-router"
import StickyNavbar from "../components/Navbar";
import Footer from "../components/Footer";

const LandingLayout = () => {
    return (
        <>
        <StickyNavbar/>
        <Outlet />
        <Footer />
        </>
    )
}

export default LandingLayout;