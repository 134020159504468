import { Typography } from "@material-tailwind/react";
import React from "react";

const DisclaimerPage = () => {
  return (
    <div className="min-h-screen flex flex-col items-center w-full py-14 px-12">
      <Typography className="text-center w-full text-6xl mb-6 font-bold font-playfair text-text-500">
        Disclaimer
      </Typography>
      <Typography className="text-center text-base mb-6 font-normal font-playfair text-text-500">
        These disclaimers are entered between you and Muktanam Ayurveda
        Services.
      </Typography>

      <div className="w-full max-w-5xl flex flex-col justify-center p-12 gap-6 items-center  rounded-2xl">
        {/* Section 1 - Introduction */}
        <div className="w-full h-full  rounded-2xl">
          <Typography className="font-montserrat font-medium text-sm text-text-500">
            The information contained in this website is for general information
            only. The information provided by Patanjaliwellness.com and while we
            endeavour to keep the information up to date and correct, we will
            not be responsible of any kind, express or implied, about the
            completeness, accuracy, reliability, suitability or availability
            with respect to the website or the information, products, services,
            or related graphics contained on the website for any purpose.{" "}
          </Typography>
        </div>

        {/* Section 2 - Services Provided */}
        <div className="w-full h-full  rounded-2xl">
          <Typography className="font-montserrat font-medium text-sm text-text-500">
            On Patanjaliwellness.com , you may also find the links to other
            websites that are not under the control of Patanjali Wellness. We
            have no control over the nature, content and availability of those
            sites. The inclusion of any links does not necessarily imply a
            recommendation or endorse the views expressed by them. Every effort
            is made to keep the website up and running smoothly. However
            Patanjali wellness takes no responsibility for, and will not be
            liable for, the website being temporarily unavailable due to
            technical issues which are beyond our control.{" "}
          </Typography>
        </div>

        {/* Section 3 - User Accounts */}
        <div className="w-full h-full  rounded-2xl">
          <Typography className="font-montserrat font-medium text-sm text-text-500">
            Content or suggestions in any form text , pictures, video or
            graphical representation on the website, facebook page or group,
            Instagram or any other Patanjali Wellness digital or non digital
            platform is purely informative and educational in nature and should
            not be construed as medical advice. Please use the content only in
            consultation with an appropriate certified medical or healthcare
            professional.
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default DisclaimerPage;
