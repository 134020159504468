import { Button, Carousel, Typography } from "@material-tailwind/react";
import Marquee from "react-fast-marquee";
import { UserSquare } from "iconsax-react";
import { useNavigate } from "react-router";
import landingC1 from "../../../assets/images/landingPage c1.jpg";
import landingC2 from "../../../assets/images/landingPage c2.jpg";
import landingC3 from "../../../assets/images/landingPage c3.jpg";
import landingC4 from "../../../assets/images/landingPage c4.jpg";
import landingC5 from "../../../assets/images/landingPage c5.jpg";
import landingC6 from "../../../assets/images/landingPage c6.jpg";
import muktanamlandinglogo from "../../../assets/images/muktanamlandinglogo.jpg";
import landingC7 from "../../../assets/images/landingPage c7.jpg";
import landingC8 from "../../../assets/images/landingPage c8.jpg";

const LandingPage = () => {
  const navigate = useNavigate();
  return (
    <>
      <Carousel
        autoplay
        loop
        transition={{ duration: 2 }}
        className="lg:h-[75vh] md:h-[65vh] sm:h-[50vh]"
        showarrows="false" // Hides the left-right arrows
      >
        <img
          src={landingC1}
          alt="image 1"
          className="h-full w-full object-fit bg-green-500"
        />
        <img
          src={landingC2}
          alt="image 2"
          className="h-full w-full object-fit bg-green-500"
        />
        <img
          src={landingC3}
          alt="image 3"
          className="h-full w-full object-fit bg-green-500"
        />
        <img
          alt="image 4"
          src={landingC4}
          className="h-full w-full object-fit bg-green-500"
        />
        <img
          alt="image 5"
          src={landingC5}
          className="h-full w-full object-fit bg-green-500"
        />
        <img
          alt="image 6"
          src={landingC6}
          className="h-full w-full object-fit bg-green-500"
        />
      </Carousel>
      <div className="bg-red-500 bg-opacity-75 text-white">
        <Marquee speed={50}>
          <Typography className="py-1 font-archivo text-lg">
            Muktanam Parmagati Solutions brings special training courses for
            Therapists. To apply for the course please click here
          </Typography>
        </Marquee>
      </div>
      <div className="w-full flex lg:flex-row md:flex-row sm:flex-col-reverse flex-col-reverse justify-between items-center px-[4vw] py-[3vw] gap-4">
        <div className="w-full h-full flex flex-col gap-2">
          <Typography className="text-4xl text-center font-semibold text-textLight-500 font-archivo">
            Muktanam Parmagati Solutions
          </Typography>
          <div>
            <Typography className="text-lg font-bold text-center text-textLight-500 font-archivo">
              समदोषः समाग्निश्च समधातुमलक्रियः।
            </Typography>
            <Typography className="text-lg font-bold text-center text-textLight-500 font-archivo">
              प्रसन्नात्मेन्द्रियमनाः स्वस्थ इत्यभिधीयते ॥
            </Typography>

            <Typography className="text-base font-medium text-center text-textLight-400 font-archivo">
              Muktanam Parmagati Solutions is a trusted leader in holistic
              health and wellness, dedicated to promoting natural and
              sustainable healing practices. Our company combines the wisdom of
              ancient traditions like Naturopathy, Nature Cure, Yoga, and
              Panchkarma with modern therapeutic approaches to provide
              personalized health solutions. With a focus on quality care, our
              experienced team ensures every treatment is delivered in a
              hygienic, eco-friendly, and luxurious environment, fostering
              complete rejuvenation and well-being.
            </Typography>
          </div>

          <div className="w-full flex lg:flex-row md:flex-row sm:flex-col-reverse flex-col-reverse justify-between items-center px-[4vw] py-[3vw] gap-4">
            <div className="w-full h-full flex flex-col gap-2">
              <Typography className="text-4xl font-semibold text-textLight-500 font-archivo">
                A unit: Muktanam Ayurveda
              </Typography>
              <Typography className="text-base font-medium text-textLight-400 font-archivo">
                Muktanam Ayurveda brings to you an integrated and complete
                health package. A fusion of, Naturopathy (the ancient Indian art
                of healing), Nature cure (drugless cure), Yoga (mind body
                balance), Panchkarma therapies (Body Detox) and multitude and
                relaxing therapies. The therapies are provided under the
                guidance of our experienced doctor and therapist in a hygienic,
                eco friendly and luxurious environment.
              </Typography>
            </div>
            <div className="w-full h-full rounded-lg flex justify-center items-center">
              <img
                src={muktanamlandinglogo}
                alt="banner"
                className="rounded-xl max-h-[50vh]"
              />
            </div>
          </div>
          <div className="w-full flex lg:flex-row md:flex-row sm:flex-col-reverse flex-col-reverse justify-between items-center px-[4vw] py-[3vw] gap-4">
            <div className="w-full h-full rounded-lg">
              <img src={landingC1} alt="banner" className="rounded-xl" />
            </div>
            <div className="w-full h-full flex flex-col gap-2">
              {/* <Typography className="text-4xl font-semibold text-textLight-500 font-archivo">
                A unit: Muktanam Ayurveda
              </Typography> */}
              <Typography className="text-base font-medium text-textLight-400 font-archivo">
                Muktanam Ayurveda presents a holistic and comprehensive health
                package designed to rejuvenate your mind and body. This package
                seamlessly combines Naturopathy (an ancient Indian healing
                practice), Nature Cure (a drug-free treatment approach), Yoga
                (for mind-body harmony), Panchakarma therapies (body
                detoxification), and a variety of relaxing therapies. All
                treatments are delivered under the expert guidance of
                experienced doctors and therapists, ensuring a hygienic,
                eco-friendly, and luxurious environment for your well-being.
              </Typography>
            </div>
          </div>

          <Typography className="text-base font-medium text-textLight-400 font-archivo">
            Muktanam Parmagati Solutions offers a holistic and comprehensive
            health package that blends ancient and modern healing practices. Our
            offerings include Naturopathy (India’s traditional healing art),
            Nature Cure (drug-free treatments), Yoga (harmony of mind and body),
            Panchkarma Therapies (body detoxification), and various relaxing
            therapies. All treatments are delivered by our skilled doctors and
            therapists in a clean, eco-friendly, and luxurious setting.
          </Typography>
          <Button
            variant="filled"
            color="green"
            size="lg"
            onClick={() => navigate("/about-us")}
            className="flex justify-center text-base font-normal items-center font-archivo bg-primaryLight-700 normal-case w-fit"
          >
            More About Us <UserSquare />
          </Button>
        </div>
        {/* <div  className="w-full h-full rounded-lg">
          <img src={landingC1} alt="banner" className="rounded-xl" />
        </div> */}
      </div>
    </>
  );
};

export default LandingPage;
