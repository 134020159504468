import React from "react";
import {
  Navbar,
  Typography,
  Button,
  IconButton,
  Collapse,
} from "@material-tailwind/react";
import Marquee from "react-fast-marquee";
import { CallCalling } from "iconsax-react";
import { IoMailOutline } from "react-icons/io5";
import logo from "../../assets/images/muktanamlogo.jpg";
import logo1 from "../../assets/images/muktanamlogo1.png";
import mainLogo from "../../assets/images/Main-logo.png";
import { useNavigate } from "react-router";

function StickyNavbar() {
  const [openNav, setOpenNav] = React.useState(false);

  React.useEffect(() => {
    window.addEventListener(
      "resize",
      () => window.innerWidth >= 960 && setOpenNav(false)
    );
  }, []);

  const navList = (
    <ul className="mt-2 mb-4 flex flex-col gap-2 lg:mb-0 lg:mt-0 lg:flex-row">
      <Typography
        as="li"
        variant="small"
        className="px-1 font-semibold text-textLight-500 font-archivo"
      >
        <a href="/about-us" className="flex items-center">
          About Us
        </a>
      </Typography>
      {/* <Typography
        as="li"
        variant="small"
        className="px-1 font-semibold text-textLight-500 font-archivo"
      >
        <a href="/" className="flex items-center">
          Treatment
        </a>
      </Typography> */}
      {/* <Typography
        as="li"
        variant="small"
        className="px-1 font-semibold text-textLight-500 font-archivo"
      >
        <a href="/" className="flex items-center">
          Disease
        </a>
      </Typography> */}
      <Typography
        as="li"
        variant="small"
        className="px-1 font-semibold text-textLight-500 font-archivo"
      >
        <a href="/for-inquiry" className="flex items-center">
          For Inquiry
        </a>
      </Typography>
      <Typography
        as="li"
        variant="small"
        className="px-1 font-semibold text-textLight-500 font-archivo"
      >
        <a href="/store" className="flex items-center">
          Store
        </a>
      </Typography>
      <Typography
        as="li"
        variant="small"
        className="px-1 font-semibold text-textLight-500 font-archivo"
      >
        <a href="/contact-us" className="flex items-center">
          Contact Us
        </a>
      </Typography>
    </ul>
  );
  const navigate = useNavigate();

  return (
    <>
      {/* Marquee Section */}
      <div className="bg-green-600 text-white">
        <Marquee speed={50}>
          <Typography className="py-1 font-archivo text-lg">
            Please beware of fraudulent messages and calls. Kindly make payments
            to Muktanam Ayurveda verified bank accounts listed here in this URL.
          </Typography>
        </Marquee>
      </div>
      <div className="w-full">
        <div className="mx-[4vw] py-1 flex flex-col lg:flex-row md:flex-col justify-between items-center gap-1">
          <div className="flex items-center flex-col lg:flex-row md:flex-row lg:gap-4 md:gap-2 gap-1">
            <Typography className="flex justify-center items-center lg:text-lg md:text-sm sm:text-sm text-sm font-normal text-textLight-500 font-archivo gap-1">
              <CallCalling style={{ height: "20px" }} /> +91-9520205177
            </Typography>
            <Typography className="flex justify-center items-center lg:text-lg md:text-sm sm:text-sm text-sm font-normal text-textLight-500 font-archivo gap-1">
              <CallCalling style={{ height: "20px" }} /> +91-1147512013
            </Typography>
            <Typography className="flex justify-center items-center lg:text-lg md:text-sm sm:text-sm text-sm font-normal text-textLight-500 font-archivo gap-1">
              <IoMailOutline style={{ height: "20px" }} />{" "}
              muktanamayurveda@gmail.com{" "}
            </Typography>
          </div>
          <div className="flex items-center gap-4">
            <img
              src="https://cdn-icons-png.flaticon.com/512/174/174848.png"
              alt="facebook"
              className="h-[24px] w-fit"
            />
            <img
              src="https://cdn-icons-png.flaticon.com/512/3256/3256013.png"
              alt="twitter"
              className="h-[24px] w-fit"
            />
            <img
              src="https://cdn-icons-png.flaticon.com/512/15713/15713420.png"
              alt="instagram"
              className="h-[24px] w-fit"
            />
            <img
              src="https://cdn-icons-png.flaticon.com/512/3938/3938026.png"
              alt="youtube"
              className="h-[24px] w-fit"
            />
          </div>
        </div>
      </div>

      <Navbar className="sticky top-0 z-10 h-max max-w-full rounded-none px-[4vw] py-1 bg-[#f9f5e9] bg-opacity-100 border-0">
        <div className="flex items-center gap-4 justify-between text-blue-gray-900">
          <div
            className="flex justify-center items-center gap-1 cursor-pointer"
            onClick={() => {
              navigate("/");
            }}
          >
            <img src={logo1} alt="logo" className="h-[70px] rounded-md" />
            <img
              src={mainLogo}
              alt="logo"
              className="h-[70px] rounded-md py-2"
            />
            {/* <div className="flex flex-col justify-around"> */}
            {/* <Typography className="font-medium text-sm text-red-900 font-rubik flex justify-start items-start text-end !mb-0 !pb-0">
                <span className="text-4xl mt-1">M</span><br />uktanam
              </Typography>
              <Typography className="font-medium text-sm text-red-900 font-rubik !mt-0 !pt-0">
                Ayurveda<span className="text-[8px]">®️</span>
              </Typography> */}
            {/* <Typography className="p-1 font-medium text-4xl text-primaryDark-800 font-rubik">
                Wellness
              </Typography> */}
            {/* </div> */}
          </div>
          <div className="flex items-center">
            <div className="hidden lg:flex text-primaryDark-800 gap-2 items-center">
              {navList}
              {/* <Button
                variant="filled"
                color="green"
                size="sm"
                className="hidden lg:inline-block font-archivo bg-primaryLight-800 normal-case"
              >
               Donate
              </Button> */}
            </div>
            <IconButton
              variant="text"
              className="ml-auto h-6 w-6 text-inherit hover:bg-transparent focus:bg-transparent active:bg-transparent lg:hidden"
              ripple={false}
              onClick={() => setOpenNav(!openNav)}
            >
              {openNav ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  className="h-6 w-6"
                  viewBox="0 0 24 24"
                  stroke="#46E356"
                  strokeWidth={2}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  stroke="#46E356"
                  strokeWidth={2}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                </svg>
              )}
            </IconButton>
          </div>
        </div>
        <Collapse open={openNav}>{navList}</Collapse>
      </Navbar>
    </>
  );
}

export default StickyNavbar;
