import { Button, Typography } from "@material-tailwind/react";
import React from "react";
import { UserSquare } from "iconsax-react";
import aboutC1 from "../../../assets/images/tulsi drop img2.jpg";
import aboutC2 from "../../../assets/images/aboutC2.jpg";
import aboutC3 from "../../../assets/images/aboutC3.jpg";
import aboutC4 from "../../../assets/images/landingPage c8.jpg";
import aboutC5 from "../../../assets/images/landingPage c7.jpg";
import aboutC6 from "../../../assets/images/aboutC4.jpg";

const AboutUs = () => {
  return (
    <>
      <div className="w-full flex lg:flex-row md:flex-row sm:flex-col-reverse flex-col-reverse justify-between items-center px-[4vw] py-[3vw] gap-4">
        <div className="w-full h-full flex flex-col gap-2">
          <Typography className="text-4xl font-semibold text-textLight-500 font-archivo">
            Muktanam Ayurveda
          </Typography>
          <div>
            <Typography className="text-lg font-bold text-textLight-500 font-archivo">
              समदोषः समाग्निश्च समधातुमलक्रियः।
            </Typography>
            <Typography className="text-lg font-bold text-textLight-500 font-archivo">
              प्रसन्नात्मेन्द्रियमनाः स्वस्थ इत्यभिधीयते ॥
            </Typography>
          </div>
          <Typography className="text-base font-medium text-textLight-400 font-archivo">
            Muktanam Ayurveda brings to you an integrated and complete health
            package. A fusion of, Naturopathy (the ancient Indian art of
            healing), Nature cure (drugless cure), Yoga (mind body balance),
            Panchkarma therapies (Body Detox) and multitude and relaxing
            therapies. The therapies are provided under the guidance of our
            experienced doctor and therapist in a hygienic, eco friendly and
            luxurious environment.
          </Typography>
          {/* <Button
            variant="filled"
            color="green"
            size="lg"
            className="flex justify-center text-base font-normal items-center font-archivo bg-primaryLight-700 normal-case w-fit"
          >
           More About Us{" "}<UserSquare />
          </Button> */}
        </div>
        <div className="w-full h-full">
          <div className="w-full h-full flex justify-center items-center">
            <img
              src={aboutC3}
              alt="banner"
              className="rounded-full w-[30vw] h-[30vw] min-w-[250px] min-h-[250px] object-cover"
            />
          </div>
        </div>
      </div>
      <div className="w-full flex flex-col gap-4 bg-[#f9f5e9] px-[4vw] py-[3vw]">
        <Typography className="text-4xl font-semibold text-textLight-500 font-archivo mb-3">
          Objectives
        </Typography>
        <Typography className="text-base font-medium text-textLight-400 font-archivo mb-3">
          Operating under the blessings of Revered Swami Ramdevji Maharaj and
          the direction and guidance of Revered Acharya Balkrishnaji, Patanjali
          Wellness is an excellent and unique concept for providing holistic
          treatment through an integrated use of Yoga, Naturopathy, Acupressure
          and harmless herbs or medicinal plants.
        </Typography>
        <Typography className="text-base font-medium text-textLight-400 font-archivo mb-3">
          Providing physical, mental and spiritual health, happiness, peace and
          prosperity to the whole afflicted mankind suffering from incurable
          diseases, through the integrated therapy of Ayurveda (āuh+veda), the
          science of ensuring longevity and health, Yoga and Naturopathy,
          Patanjali Wellness presents a unique combination of the ancient
          traditional medical system and the modern diagnostics.
        </Typography>
      </div>
      {/* <div className="w-full h-full px-[4vw] pt-[3vw]">
        <Typography className="text-4xl text-center font-semibold text-textLight-400 font-archivo">
          Our Leadership
        </Typography>
      </div> */}
      <div className="w-full flex lg:flex-row md:flex-row sm:flex-col-reverse flex-col-reverse justify-between items-center px-[4vw] py-[3vw] gap-4">
        <div className="w-full h-full flex flex-col gap-2">
          {/* <Typography className="text-4xl font-semibold text-textLight-500 font-archivo">
            Muktanam Ayurveda
          </Typography>
          <div>
            <Typography className="text-lg font-bold text-textLight-500 font-archivo">
              समदोषः समाग्निश्च समधातुमलक्रियः।
            </Typography>
            <Typography className="text-lg font-bold text-textLight-500 font-archivo">
              प्रसन्नात्मेन्द्रियमनाः स्वस्थ इत्यभिधीयते ॥
            </Typography>
          </div> */}
          <Typography className="text-base font-medium text-textLight-400 font-archivo">
            "Experience the goodness of Muktanam Ayurveda Hair Oil, enriched
            with natural ingredients and Vitamin E. Specially formulated for: ✓
            Promoting hair growth ✓ Adding silky smooth texture ✓ Supporting
            longer, stronger hair ✓ Revitalizing hair health naturally Let
            Muktanam Ayurveda bring you closer to the roots of nature with
            premium quality hair care solutions."
          </Typography>
          {/* <Button
                variant="filled"
                color="green"
                size="lg"
                className="flex justify-center text-base font-normal items-center font-archivo bg-primaryLight-700 normal-case w-fit"
              >
               More About Us{" "}<UserSquare />
              </Button> */}
        </div>
        <div className="w-full h-full flex items-center justify-center">
          <img
            src={aboutC2}
            alt="banner"
            className="max-h-[350px] rounded-xl"
          />
        </div>
      </div>
      <div className="w-full flex lg:flex-row md:flex-row sm:flex-col-reverse flex-col-reverse justify-between items-center px-[4vw] py-[3vw] gap-4">
        <div className="w-full h-full flex items-center justify-center">
          <img
            src={aboutC1}
            alt="banner"
            className="max-h-[350px] rounded-xl"
          />
        </div>
        <div className="w-full h-full flex flex-col gap-4">
          <Typography className="text-base font-medium text-textLight-400 font-archivo">
            "Discover holistic solutions for managing diabetes with Muktanam
            Ayurveda. Embrace the power of natural healing through personalized
            therapies, yoga for body and mind harmony, and Panchakarma detox
            treatments. Let our experienced team guide you toward balanced
            health in a serene and eco-friendly setting."
          </Typography>
        </div>
      </div>
      <div className="w-full flex lg:flex-row md:flex-row sm:flex-col-reverse flex-col-reverse justify-between items-center px-[4vw] py-[3vw] gap-4">
        <div className="w-full h-full flex flex-col gap-4">
          <Typography className="text-base font-medium text-textLight-400 font-archivo">
            "Muktanam Night Power Ayurvedic Capsules are a natural blend of
            potent herbs designed to enhance vitality, stamina, and overall
            well-being. Formulated with traditional Ayurvedic principles, these
            capsules help combat stress, improve sleep quality, and support
            energy restoration. Crafted with 100% natural ingredients, they are
            free from harmful chemicals, making them a safe and effective choice
            for rejuvenation and sustained energy. Experience the balance of
            body and mind with Muktanam Night Power."
          </Typography>
        </div>
        <div className="w-full h-full flex items-center justify-center">
          <img
            src={aboutC4}
            alt="banner"
            className="max-h-[350px] rounded-xl"
          />
        </div>
      </div>
      <div className="w-full flex lg:flex-row md:flex-row sm:flex-col-reverse flex-col-reverse justify-between items-center px-[4vw] py-[3vw] gap-4">
        <div className="w-full h-full flex items-center justify-center">
          <img
            src={aboutC5}
            alt="banner"
            className="max-h-[350px] rounded-xl"
          />
        </div>
        <div className="w-full h-full flex flex-col gap-4">
          <Typography className="text-base font-medium text-textLight-400 font-archivo">
            "Muktanam Joint Pain Ayurvedic Capsules are a powerful herbal
            formulation designed to relieve joint discomfort, reduce
            inflammation, and improve mobility. Rooted in traditional Ayurvedic
            wisdom, these capsules support healthy joints by promoting natural
            healing and strengthening. Made with 100% natural ingredients, they
            are free from chemicals and side effects, offering a safe and
            effective solution for long-term joint care. Regain your freedom of
            movement with Muktanam Joint Pain Capsules."
          </Typography>
        </div>
      </div>
      <div className="w-full flex lg:flex-row md:flex-row sm:flex-col-reverse flex-col-reverse justify-between items-center px-[4vw] py-[3vw] gap-4">
        <div className="w-full h-full flex flex-col gap-4">
          <Typography className="text-base font-medium text-textLight-400 font-archivo">
            "Muktanam Sugar Vati Ayurvedic Capsules are a natural formulation
            crafted to support healthy blood sugar levels and overall metabolic
            balance. Based on Ayurvedic principles, these capsules help regulate
            sugar levels, enhance pancreatic health, and promote better glucose
            metabolism. Made with 100% natural and chemical-free ingredients,
            they provide a safe and effective solution for managing sugar levels
            naturally. Take control of your health with Muktanam Sugar Vati
            Ayurvedic Capsules."
          </Typography>
        </div>
        <div className="w-full h-full flex items-center justify-center">
          <img
            src={aboutC6}
            alt="banner"
            className="max-h-[350px] rounded-xl"
          />
        </div>
      </div>
    </>
  );
};

export default AboutUs;
