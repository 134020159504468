import React, { useState } from "react";
import { Typography, Input, Textarea, Button } from "@material-tailwind/react";
import { IoEye } from "react-icons/io5";
import { FaFacebook, FaLinkedin, FaTwitter } from "react-icons/fa";
import { IoCall } from "react-icons/io5";
import { IoLocationSharp } from "react-icons/io5";
import { FaEnvelope } from "react-icons/fa";
import { useForm } from "react-hook-form";
import sendMail from "../../../apis/contactUs";

const ContactUs = () => {
  const [loading, setLoading] = useState(false);
  const [messaage, setMessage] = useState("");
  const [error, setError] = useState("");
  const { register, handleSubmit } = useForm();
  const handleContactUs = async (data) => {
    const res = await sendMail(data, setLoading);

    if (res.success) {
      setMessage(res.message);
    } else {
      setError(res.message);
    }
  };
  return (
    <div className="p-[4vw]">
      <div className="flex flex-col md:flex-row items-start md:items-center justify-between border-b border-[#E4E4E7] pb-10">
        <Typography className="text-text-500 font-Be_Vietnam_Pro text-4xl font-semibold w-full md:w-1/2 lg:w-1/2 sm:w-full">
          Contact Us
        </Typography>
        <Typography className="text-text-400 font-Be_Vietnam_Pro font-medium text-sm w-full md:w-1/2 lg:w-1/2 sm:w-full">
          Muktanaam Ayurveda is more than just a workplace – it’s a community of
          like-minded professionals driven by a shared purpose. Here, you’ll
          have the opportunity to work on meaningful projects, collaborate with
          industry experts, and develop skills that propel your career forward.
          We believe in nurturing talent and providing our team with the
          resources, training, and support needed to succeed in an ever-evolving
          cybersecurity landscape. Join us and grow with a team that’s dedicated
          to making a difference.
        </Typography>
      </div>

      {/* Form Section */}
      <div className="mt-20 grid grid-cols-1 md:grid-cols-2 gap-8 rounded-xl  bg-[#000000] bg-opacity-5">
        <form onSubmit={handleSubmit(handleContactUs)}>
          <div className="space-y-6 p-[3vw] border-r border-[#f1f1f3]">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div className="w-full">
                <Typography className="font-Be_Vietnam_Pro text-base font-medium text-text-500 mb-2">
                  First Name
                </Typography>
                <Input
                  type="outlined"
                  placeholder="First Name"
                  className="!border py-6 !rounded-lg !border-[#F1F1F3] placeholder:font-Be_Vietnam_Pro font-Be_Vietnam_Pro !bg-[#FCFCFD] text-text-500 shadow-none ring-0 ring-transparent placeholder:text-text-400 placeholder:opacity-100 focus:!border-[#F1F1F3] focus:!border-t-[#F1F1F3] focus:ring-0"
                  labelProps={{
                    className: "hidden",
                  }}
                  containerProps={{ className: "min-w-[100px]" }}
                  {...register("fistName")}
                />
              </div>
              <div className="w-full">
                <Typography className="font-Be_Vietnam_Pro text-base font-medium text-text-500 mb-2">
                  Last Name
                </Typography>
                <Input
                  type="outlined"
                  placeholder="Last Name"
                  className="!border py-6 !rounded-lg !border-[#F1F1F3] placeholder:font-Be_Vietnam_Pro font-Be_Vietnam_Pro !bg-[#FCFCFD] text-text-500 shadow-none ring-0 ring-transparent placeholder:text-text-400 placeholder:opacity-100 focus:!border-[#F1F1F3] focus:!border-t-[#F1F1F3] focus:ring-0"
                  labelProps={{
                    className: "hidden",
                  }}
                  containerProps={{ className: "min-w-[100px]" }}
                  {...register("lastName")}
                />
              </div>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div className="w-full">
                <Typography className="font-Be_Vietnam_Pro text-base font-medium text-text-500 mb-2">
                  Email
                </Typography>
                <Input
                  type="email"
                  placeholder="Email Address"
                  className="!border py-6 !rounded-lg !border-[#F1F1F3] placeholder:font-Be_Vietnam_Pro font-Be_Vietnam_Pro !bg-[#FCFCFD] text-text-500 shadow-none ring-0 ring-transparent placeholder:text-text-400 placeholder:opacity-100 focus:!border-[#F1F1F3] focus:!border-t-[#F1F1F3] focus:ring-0"
                  labelProps={{
                    className: "hidden",
                  }}
                  containerProps={{ className: "min-w-[100px]" }}
                  {...register("email")}
                />
              </div>
              <div className="w-full">
                <Typography className="font-Be_Vietnam_Pro text-base font-medium text-text-500 mb-2">
                  Phone Number
                </Typography>
                <Input
                  type="text"
                  placeholder="Enter your phone number"
                  className="!border py-6 !rounded-lg !border-[#F1F1F3] placeholder:font-Be_Vietnam_Pro font-Be_Vietnam_Pro !bg-[#FCFCFD] text-text-500 shadow-none ring-0 ring-transparent placeholder:text-text-400 placeholder:opacity-100 focus:!border-[#F1F1F3] focus:!border-t-[#F1F1F3] focus:ring-0"
                  labelProps={{
                    className: "hidden",
                  }}
                  containerProps={{ className: "min-w-[100px]" }}
                  {...register("phone")}
                />
              </div>
            </div>
            <Textarea
              label="Message"
              placeholder="Enter your Message here..."
              className="!border py-6 !rounded-lg !border-[#F1F1F3] placeholder:font-Be_Vietnam_Pro font-Be_Vietnam_Pro !bg-[#FCFCFD] text-text-500 shadow-none ring-0 ring-transparent placeholder:text-text-400 placeholder:opacity-100 focus:!border-[#F1F1F3] focus:!border-t-[#F1F1F3] focus:ring-0"
              labelProps={{
                className: "hidden",
              }}
              {...register("content")}
            />

            {messaage ? (
              <p className="text-sm mt-10 mb-10 text-green-400">{messaage}</p>
            ) : (
              <p className="text-sm mt-10 mb-10 text-red-400">{error}</p>
            )}
            <div className="w-full flex justify-center items-center">
              <Button
                variant="filled"
                className="normal-case shadow-none hover:shadow-none font-semibold text-base font-Be_Vietnam_Pro py-2 px-6 text-white bg-primaryLight-500 focus:ring-0"
                type="submit"
                disabled={loading}>
                {loading ? "Sending..." : "Send Your Message"}
              </Button>
            </div>
          </div>
        </form>

        {/* Contact Info Section */}
        <div className="space-y-6 py-[3vw] h-full w-full flex flex-col justify-between items-center">
          <div className="flex items-center justify-center space-x-2 w-full h-full">
            <div className="flex flex-col items-center justify-center text-text-400 gap-5 py-5 lg:max-w-[60%] md:max-w-[75%] sm:max-w-[55%] max-w-[90%] w-full px-5 h-full border-2 rounded-xl border-[#f1f1f3] bg-[#fcfcfd]">
              <div className="text-2xl bg-text-100 p-3 rounded-lg">
                <FaEnvelope className="text-text-400" />
              </div>
              <Typography className="text-text-400 font-Be_Vietnam_Pro font-medium text-sm">
                muktanamayurveda@gmail.com
              </Typography>
            </div>
          </div>
          <div className="flex items-center justify-center space-x-2 w-full h-full">
            <div className="flex flex-col items-center justify-center text-text-400 gap-5 py-5 lg:max-w-[60%] md:max-w-[75%] sm:max-w-[55%] max-w-[90%] w-full px-5 h-full border-2 rounded-xl border-[#f1f1f3] bg-[#fcfcfd]">
              <div className="text-2xl bg-text-100 p-3 rounded-lg">
                <IoCall className="text-text-400" />
              </div>
              <Typography className="text-text-400 font-Be_Vietnam_Pro font-medium text-sm">
                +91-9520205177
              </Typography>
            </div>
          </div>
          <div className="flex items-center justify-center space-x-2 w-full h-full">
            <div className="flex flex-col items-center justify-center text-text-400 gap-5 py-5 lg:max-w-[60%] md:max-w-[75%] sm:max-w-[55%] max-w-[90%] w-full px-5 h-full border-2 rounded-xl border-[#f1f1f3] bg-[#fcfcfd]">
              <div className="text-2xl bg-text-100 p-3 rounded-lg">
                <IoLocationSharp className="text-text-400" />
              </div>
              <Typography className="text-text-400 font-Be_Vietnam_Pro font-medium text-sm">
                Somewhere in the World
              </Typography>
            </div>
          </div>
          <div className="flex items-center justify-center space-x-2 w-full h-full">
            <div className="flex flex-col items-center justify-center text-text-400 gap-5 py-5 lg:max-w-[60%] md:max-w-[75%] sm:max-w-[55%] max-w-[90%] w-full px-5 h-full border-2 rounded-xl border-[#f1f1f3] bg-[#fcfcfd]">
              <div className="flex items-start gap-2">
                <div className="text-2xl bg-text-100 p-3 rounded-lg">
                  <FaFacebook className="text-text-400" />
                </div>
                <div className="text-2xl bg-text-100 p-3 rounded-lg">
                  <FaTwitter className="text-text-400" />
                </div>
                <div className="text-2xl bg-text-100 p-3 rounded-lg">
                  <FaLinkedin className="text-text-400" />
                </div>
              </div>
              <Typography className="text-text-400 font-Be_Vietnam_Pro font-medium text-sm">
                Social Profiles
              </Typography>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
