import logo from './logo.svg';
import './App.css';
import Router from './routes';
import StickyNavbar from './common/components/Navbar';
import SidebarWithLogo from './common/components/Sidebar';
import ScrollToTop from './common/components/ScrollTop';

function App() {
  return (
    <>
   <ScrollToTop />
      <Router />
    </>
  );
}

export default App;
