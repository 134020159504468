import { Typography } from "@material-tailwind/react";
import React from "react";

const Legal = () => {
  return (
    <div className="min-h-screen flex flex-col items-center w-full py-14 px-12">
      <Typography className="text-center w-full text-6xl mb-6 font-bold font-playfair text-text-500">
        Legal Notice
      </Typography>
      <Typography className="text-center text-base mb-6 font-normal font-playfair text-text-500">
        These Terms are entered between you and Muktanam Ayurveda Services.
      </Typography>

      <div className="w-full max-w-5xl flex flex-col justify-center p-12 gap-6 items-center  rounded-2xl">
        {/* Introduction */}
        <div className="w-full h-full   rounded-2xl">
          <Typography className="font-montserrat font-semibold text-lg text-text-500">
            1. Introduction
          </Typography>
          <Typography className="font-montserrat font-medium text-sm text-text-500">
            Welcome to Muktanam Ayurveda Services ("Muktanam Ayurveda", "we", "us", "our").
            This Legal Notice outlines important legal information regarding our
            website, mobile application, and services (collectively, the
            "Services"). By accessing or using our Services, you agree to comply
            with and be bound by this Legal Notice. If you do not agree with
            this Legal Notice, please do not use our Services.
          </Typography>
        </div>

        {/* Ownership of the Services */}
        <div className="w-full h-full   rounded-2xl">
          <Typography className="font-montserrat font-semibold text-lg text-text-500">
            2. Ownership of the Services
          </Typography>
          <Typography className="font-montserrat font-medium text-sm text-text-500">
            The Services are owned and operated by Muktanam Ayurveda. All content, features,
            and functionality (including but not limited to all information,
            software, text, displays, images, video, and audio, and the design,
            selection, and arrangement thereof) are the exclusive property of
            Muktanam Ayurveda, its licensors, or other providers of such material and are
            protected by international copyright, trademark, patent, trade
            secret, and other intellectual property or proprietary rights laws.
          </Typography>
        </div>

        {/* Use of the Services */}
        <div className="w-full h-full   rounded-2xl">
          <Typography className="font-montserrat font-semibold text-lg text-text-500">
            3. Use of the Services
          </Typography>
          <Typography className="font-montserrat font-medium text-sm text-text-500">
            <strong>3.1. Permitted Use:</strong> You may use the Services for
            your personal, non-commercial use only. Any other use of the
            Services, including but not limited to the reproduction,
            distribution, display, or transmission of the content of the
            Services, is strictly prohibited unless authorized by Muktanam Ayurveda.
            <br />
            <br />
            <strong>3.2. Prohibited Use:</strong> You agree not to:
            <ul className="list-disc ml-6">
              <li>
                Use the Services in any manner that could disable, overburden,
                damage, or impair the Services or interfere with any other
                party's use of the Services.
              </li>
              <li>
                Use any robot, spider, or other automatic device, process, or
                means to access the Services for any purpose.
              </li>
              <li>Use the Services for any fraudulent or unlawful purpose.</li>
            </ul>
          </Typography>
        </div>

        {/* Disclaimers and Limitation of Liability */}
        <div className="w-full h-full   rounded-2xl">
          <Typography className="font-montserrat font-semibold text-lg text-text-500">
            4. Disclaimers and Limitation of Liability
          </Typography>
          <Typography className="font-montserrat font-medium text-sm text-text-500">
            <strong>4.1. Disclaimers:</strong> The Services are provided on an
            "as is" and "as available" basis. Muktanam Ayurveda makes no representations or
            warranties of any kind, express or implied, as to the operation of
            the Services or the information, content, materials, or products
            included on or otherwise made available to you through the Services.
            <br />
            <br />
            <strong>4.2. Limitation of Liability:</strong> To the maximum extent
            permitted by law, Muktanam Ayurveda shall not be liable for any indirect,
            incidental, special, consequential, or punitive damages, or any loss
            of profits or revenues, whether incurred directly or indirectly, or
            any loss of data, use, goodwill, or other intangible losses,
            resulting from:
            <ul className="list-disc ml-6">
              <li>Your use or inability to use the Services.</li>
              <li>
                Any conduct or content of any third party on the Services.
              </li>
              <li>
                Any unauthorized access, use, or alteration of your
                transmissions or content.
              </li>
            </ul>
          </Typography>
        </div>

        {/* Indemnification */}
        <div className="w-full h-full   rounded-2xl">
          <Typography className="font-montserrat font-semibold text-lg text-text-500">
            5. Indemnification
          </Typography>
          <Typography className="font-montserrat font-medium text-sm text-text-500">
            You agree to indemnify, defend, and hold harmless Muktanam Ayurveda and its
            affiliates, officers, directors, employees, and agents from and
            against any and all claims, liabilities, damages, losses, and
            expenses, including reasonable attorneys' fees and costs, arising
            out of or in any way connected with your access to or use of the
            Services.
          </Typography>
        </div>

        {/* Governing Law */}
        <div className="w-full h-full   rounded-2xl">
          <Typography className="font-montserrat font-semibold text-lg text-text-500">
            6. Governing Law
          </Typography>
          <Typography className="font-montserrat font-medium text-sm text-text-500">
            This Legal Notice shall be governed and construed in accordance with
            the laws of the jurisdiction in which Muktanam Ayurveda operates, without regard
            to its conflict of law provisions.
          </Typography>
        </div>

        {/* Changes to this Legal Notice */}
        <div className="w-full h-full   rounded-2xl">
          <Typography className="font-montserrat font-semibold text-lg text-text-500">
            7. Changes to this Legal Notice
          </Typography>
          <Typography className="font-montserrat font-medium text-sm text-text-500">
            Muktanam Ayurveda reserves the right to modify this Legal Notice at any time. We
            will notify you of any changes by posting the new Legal Notice on
            our platform. Your continued use of the Services after any such
            changes constitutes your acceptance of the new Legal Notice.
          </Typography>
        </div>

        {/* Contact Us */}
        <div className="w-full h-full   rounded-2xl">
          <Typography className="font-montserrat font-semibold text-lg text-text-500">
            8. Contact Us
          </Typography>
          <Typography className="font-montserrat font-medium text-sm text-text-500">
            If you have any questions about this Legal Notice, please contact us
            at:
            <br />
            Email: muktanamayurveda@gmail.com
            <br />
            Phone: +91-9520205177
            <br />
            Address: Delhi-Haridwar National Highway, Near Bahadrabad, Haridwar-249405, Uttarakhand, Bharat
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default Legal;
