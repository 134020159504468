import {
    Navigate,
    useLocation,
    useNavigate,
    useParams,
    useRoutes
} from 'react-router-dom';
import LandingLayout from '../common/layout/LandingLayout';
import LandingPage from '../containers/Pages/LandingPage/LandingPage';
import HomeLayout from '../common/layout/HomeLayout';
import HomePage from '../containers/Pages/HomePage/HomePage';
import Legal from '../containers/Pages/Legal';
import Privacy from '../containers/Pages/Privacy';
import TermsAndConditions from '../containers/Pages/TermsAndConditions';
import DisclaimerPage from '../containers/Pages/Disclaimer/DisclaimerPage';
import MedicineStore from '../containers/Pages/MedicineStore';
import AboutUs from '../containers/Pages/AboutUs';
import ContactUs from '../containers/Pages/ContactUs';
import CareerPage from '../containers/Pages/CareersPage';

const Router = () => {
    const navigate = useNavigate();
    const loggedin = false;

    return useRoutes([
        {
            path: '/',
            element: !loggedin ? <LandingLayout /> : <HomeLayout />,
            children: [
                { path: '', element: !loggedin ? <LandingPage /> : <HomePage /> },
                { path: 'terms-and-conditions', element: <TermsAndConditions /> },
                { path: 'privacy-policy', element: <Privacy /> },
                { path: 'legal-policy', element: <Legal /> },
                { path: 'disclaimer', element: <DisclaimerPage /> },
                { path: 'store', element: <MedicineStore /> },
                { path: 'about-us', element: <AboutUs /> },
                { path: 'contact-us', element: <ContactUs /> },
                { path: 'for-inquiry', element: <CareerPage /> },
            ]
        }
    ])

};

export default Router;
